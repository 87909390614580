<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 16:29:32
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-01-17 21:35:39
-->
<template>
  <div class="page-box">
    <list @load="getList">
      <div class="header flex-x center" v-if="$route.query.status == 4">
        <span class="header__title">本月待结算金额</span>
        <span class="header__price grow">{{ amount }}</span>
        <div class="header__picker flex-x center" @click="showMonth = true">
          <span class="grow">{{ month }}月</span>
          <div class="header__arrow"></div>
        </div>
      </div>

      <div class="order" v-for="(item, index) in list" :key="index">
        <order-card
          :info="item"
          :btns="checkBtn(item, btns)"
          :showSeal="!!checkIcon(item, icons)"
          :sealIcon="checkIcon(item, icons)"
          @onclickbtn="clickBtn"
          @ondetail="detail"
        />
      </div>
    </list>

    <msg-box v-model="showNone" @onclick="$router.back()" />

    <!-- 驳回新工单 -->
    <turn-down
      v-model="showTurnDown"
      @onsubmit="submitTurnDown"
      ref="turnDown"
    ></turn-down>
  </div>
</template>

<script>
import { List, Toast } from "vant";
import turnDown from "../../../components/turnDown/turnDown";
import msgBox from "../../../components/msgBox/msgBox";
import orderCard from "@/components/OrderCard/OrderCard";
import pageLoader from "../../../utils/load_per_page";
import { orderList, confirmOrder } from "../../../utils/api/otherWorkOrder";
import { confirm as confirmQuotation } from "../../../utils/api/quotation";
import { confirmOrder as confirmAcceptance } from "../../../utils/api/acceptance";
export default {
  components: {
    "order-card": orderCard,
    list: List,
    "msg-box": msgBox,
    "turn-down": turnDown,
  },
  data() {
    return {
      turnDownIcon: require("../../../assets/images/user/review_turndown.png"), // 驳回图片

      listLoader: null,

      list: [],

      showNone: false, // 显示没有列表

      showTurnDown: false, // 显示驳回弹窗

      prepareInfo: 0, // 准备操作的内容
      prepareBtnInfo: {}, // 准备的按钮信息

      showMonth: false, // 显示选择年月

      year: new Date().getFullYear(), // 当前选中年
      month: new Date().getMonth() + 1, // 当前选中月

      amount: 0, // 结算金额

      // 图标
      icons: [
        {
          // 勘察
          img: require("../../../assets/images/user/review_survey.png"),
          condition: { otherStatus: 1, "label.status": 3 },
        },
        {
          // 驳回
          img: require("../../../assets/images/user/review_turndown.png"),
          condition: { otherStatus: 1, "label.status": 4, "label.review": 2 },
        },
        {
          // 勘察
          img: require("../../../assets/images/user/review_survey.png"),
          condition: { otherStatus: 1, "label.status": 4 },
        },
        {
          // 驳回
          img: require("../../../assets/images/user/review_turndown.png"),
          condition: { otherStatus: 2, "label.status": 4, "label.review": 2 },
        },
        {
          // 验收
          img: require("../../../assets/images/user/review_waitacceptance.png"),
          condition: { otherStatus: 3 },
        },
        {
          // 待结算
          img: require("../../../assets/images/user/review_waitsettle.png"),
          condition: { otherStatus: 4, "label.status": 0 },
        },
        {
          // 已结算
          img: require("../../../assets/images/user/review_waitsettle.png"),
          condition: { otherStatus: 4, "label.status": 1 },
        },
      ],

      // 按钮
      btns: [
        {
          text: "驳回",
          method: "turnDown",
          condition: { otherStatus: 0 },
          api: confirmOrder, // 需要用到的api
          idKey: "id", // 需要api发送的id的key
        },
        {
          text: "通过",
          method: "pass",
          type: "primary",
          condition: { otherStatus: 0 },
          api: confirmOrder,
          idKey: "id",
        },
        {
          text: "查看报价",
          method: "viewOffer",
          condition: { otherStatus: 1 },
        },
        {
          text: "驳回",
          method: "turnDown",
          condition: { otherStatus: 1 },
          api: confirmQuotation,
          idKey: "label",
        },
        {
          text: "通过",
          method: "pass",
          type: "primary",
          condition: { otherStatus: 1 },
          api: confirmQuotation,
          idKey: "label",
        },
        {
          text: "驳回",
          method: "turnDown",
          condition: { otherStatus: 2 },
          api: confirmAcceptance,
          idKey: "label",
        },
        {
          text: "通过",
          method: "pass",
          type: "primary",
          condition: { otherStatus: 2 },
          api: confirmAcceptance,
          idKey: "label",
        },
      ],
    };
  },

  created() {},

  methods: {
    // 获取列表
    getList() {
      if (!this.listLoader)
        this.listLoader = new pageLoader(
          orderList,
          "data.data.list.data",
          true,
          "data.data.list.last_page"
        );
      this.listLoader
        .load({
          status: this.$route.query.status,
          month:
            this.$route.query.status == 4 ? `${this.year}-${this.month}` : "",
        })
        .then((res) => {
          if (res && res.length === 0) this.showNone = true;
          if (res && res.length) {
            this.showNone = false;
            this.list = res;
          }
        })
        .catch((res) => {
          Toast(res.data.msg)
        });
    },

    // 重新获取列表
    reGetList() {
      this.listLoader.reLoad();
      this.getList();
    },

    // 检查按钮并返回应该显示
    checkBtn(info, btns) {
      let result = [];

      for (let i = 0; i < btns.length; i++) {
        // 如果按钮没有条件
        if (!btns[i].condition) {
          result.push(btns[i]);
          continue;
        }

        let condition = btns[i].condition;

        if (this.checkCondition(info, condition)) result.push(btns[i]);
      }

      return result;
    },

    // 检查图标
    checkIcon(info, icons) {
      for (let i = 0; i < icons.length; i++) {
        let condition = icons[i].condition;

        if (this.checkCondition(info, condition)) {
          return icons[i].img;
        }
      }
      return "";
    },

    // 检查条件内容
    checkCondition(obj, condition) {
      let checkPassed = true; // 检查通过
      for (let j in condition) {
        let position = j.split(".");
        let value = obj;
        while (position.length > 0) {
          value = value[position[0]];
          position.splice(0, 1);
        }
        if (condition[j] !== value) {
          checkPassed = false;
          break;
        }
      }
      return checkPassed;
    },

    // 点击按钮
    clickBtn(e) {
      if (!e.detail.method) return false;
      this[e.detail.method](e.info.id, e.info, e.detail);
    },

    // 驳回
    turnDown(id, info, btn) {
      this.prepareBtnInfo = btn;
      this.prepareInfo = info;
      this.showTurnDown = true;
    },

    // 发送驳回 工单 报价单 维修单
    submitTurnDown(e) {
      this.prepareBtnInfo
        .api({
          id: this.prepareInfo[this.prepareBtnInfo.idKey],
          review: 2,
          reason: e.detail.text,
        })
        .then((res) => {
          Toast.success("驳回成功");
          this.showTurnDown = false;
          this.$refs.turnDown.clear();
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 通过订单 报价单 验收单
    pass(id, info, btn) {
      btn
        .api({
          id: info[btn.idKey],
          review: 1,
        })
        .then((res) => {
          Toast.success("通过成功");
          this.reGetList();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 查看报价
    viewOffer(id, info) {
      this.$router.push({
        name: "ReviewOffer",
        query: {
          id: info.label,
        },
      });
    },

    // 详情
    detail(e) {
      this.$router.push({
        name: "ReviewOrderDetail",
        query: {
          id: e.info.id,
        },
      });
    },
  },
};
</script> 

<style src="@/assets/css/review/newTicket.css" scoped>
</style>

<style scoped>
.header {
  width: 100%;
  height: 98px;
  position: sticky;
  z-index: 99999;
  top: 0;
  background-color: white;
  box-sizing: border-box;
  padding: 0 33px;
}

.header__title {
  font-size: 28px;
  color: #222;
}

.header__price {
  font-size: 46px;
  color: var(--main-color);
  text-decoration: underline;
  margin-left: 18px;
}

.header__picker {
  width: 204px;
  height: 58px;
  border: 1px solid #999;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 12px 0 21px;
  font-size: 28px;
}

.header__arrow {
  width: 42px;
  height: 42px;
  background-image: url("../../../assets/images/review/form_arrow.png");
  background-size: contain;
  background-repeat: no-repeat;
}
</style>

<style src="@/assets/css/review/newTicket_pc.css" scoped>
</style>